<template>
  <!-- 用户中心首页头部模块 -->
  <div class="headModel">
    <!-- 用户名等 -->
    <div class="userInfoBox">
      <div class="userImg">
        <van-image lazy-load class="userPicture" :src="userInfo.portrait_url">
          <template v-slot:loading>
            <img
              :src="require('@/assets/homePage/defaultUserHead.png')"
              alt=""
              class="loadingImg"
            />
          </template>
        </van-image>

        <img
          class="membershipLevelIcon"
          :src="userInfo.level.currentLevel.level_icon"
          alt=""
        />
      </div>
      <div class="userFontInfo">
        <h1 class="userName">
          <span class="nameFont">{{ userInfo.realname }} 様 </span>
          <span>(ID：{{ userInfo.operation_id }})</span>
        </h1>
        <div class="chargeProgressBar">
          <div :style="'width:' + userInfo.level.nextLevelProcess + '%'"></div>
        </div>
        <div class="rateInformation">
          <span>
            {{ $fun.roundNumber(userInfo.level.nextLevelProcess) || 0 }}%
          </span>
          <span class="gray">
            {{ $fanyi("手续费") }}：{{
              (userInfo.level.currentLevel.service_rate || 0) * 100
            }}%
          </span>
        </div>
      </div>
      <div class="openIcon" @click="$fun.routerToPage('/user/userDetail')">
        <img :src="require('@/assets/homePage/shezhi.png')" alt="" />
      </div>
    </div>
    <!-- 用户余额等 -->
    <div class="accountBalance">
      <div class="accountBalanceOpt" @click="$fun.routerToPage('/user/purse')">
        <b>{{ $fun.JPYNumSegmentation(userInfo.balance) }}</b>
        <span> {{ $fanyi("余额") }}({{ $fanyi("円") }}) </span>
        <!-- <div class="smallTag">{{ $fanyi("钱包") }}</div> -->
      </div>
      <div class="accountBalanceOpt" @click="$fun.routerToPage('/user/coupon')">
        <b>{{ $store.state.userInfo.discount_count }}</b>
        <span> {{ $fanyi("劵包") }}({{ $fanyi("枚") }}) </span>
      </div>
      <div class="accountBalanceOpt" @click="$fun.routerToPage('/user/inform')">
        <b>{{ $store.state.userInfo.system_message_unread_count }}</b>
        <span> {{ $fanyi("通知") }}({{ $fanyi("条") }}) </span>
      </div>
    </div>
    <!-- 会员标志 -->
    <div class="membershipLogo" @click="$fun.routerToPage('/Memberfee')">
      <img :src="require('@/assets/homePage/huiyuan.png')" alt="" />
      <template
        v-if="
          $store.state.userInfo.level &&
          ['SVIP', '定額会員'].includes(
            $store.state.userInfo.level.currentLevel.level_name
          )
        "
      >
        <span
          >{{ $fanyi("收费会员") }}：{{
            $store.state.userInfo.level.currentLevel.end_date_format
          }}
          {{ $store.state.userInfo.level.currentLevel.end_time_format }} (残り{{
            getDateDifference(
              $store.state.userInfo.level.currentLevel.end_date
            )
          }}日)</span
        >
      </template>
      <template v-else>
        <span>{{ $fanyi("收费会员") }}</span>
        <button>
          {{ $fanyi("立即开通") }}
        </button>
      </template>
    </div>
    <div class="coBranding">
      <img :src="require('@/assets/icon/app1688row.png')" alt="" />
    </div>
  </div>
</template>
<script>
import { getCurrentInstance, computed } from "vue";
import { getDateDifference } from "@/utlis/date.js";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    const userInfo = computed(() => {
      return proxy.$store.state.userInfo;
    });

    return {
      userInfo,
    };
  },
  data() {
    return { getDateDifference };
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headModel {
  min-height: 430px;
  padding: 60px 0 0;

  //   background: linear-gradient(180deg, #ffedea, #ffffff);
  .userInfoBox {
    display: flex;
    margin-bottom: 60px;
    padding: 0 30px;

    .userImg {
      width: 120px;
      height: 120px;
      position: relative;
      margin-right: 30px;

      .userPicture {
        background-color: #ffdbd8;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 30px;

        :deep().van-image__loading {
          background-color: transparent;
        }
      }
      .loadingImg {
        width: 44px;
        height: 56px;
      }
      .membershipLevelIcon {
        $size: 40px;
        width: $size;
        height: $size;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .userFontInfo {
      h1 {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 32px;
          line-height: 48px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
          color: #000000;
        }
        span.nameFont {
          max-width: 250px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .chargeProgressBar {
        width: 320px;
        border: 2px solid #b4272b;
        height: 12px;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 10px;

        div {
          height: 100%;
          border-radius: 6px;
          background-color: #b4272b;
        }
      }

      .rateInformation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 30px;

        * {
          font-size: 20px;
        }

        .gray {
          color: #999999;
        }
      }
    }

    .openIcon {
      flex: 1;
      text-align: right;

      img {
        $size: 40px;
        width: $size;
        height: $size;
        margin-right: 4px;
      }
    }
  }

  .accountBalance {
    display: flex;
    margin-bottom: 30px;

    .accountBalanceOpt {
      flex: 1;
      position: relative;
      border-right: solid 1px #f2f2f2;
      * {
        text-align: center;
        line-height: 40px;
        display: block;
      }

      b {
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: 500;
      }

      span {
        font-size: 24px;
        color: #999999;
      }
      .smallTag {
        position: absolute;
        top: 55px;
        right: 15px;
        width: 64px;
        height: 30px;
        background: #ff4437;
        border-radius: 10px 0px 10px 0px;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .membershipLogo {
    margin: 0 30px;
    height: 100px;
    background-color: #101010;
    padding: 20px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 1px rgba(180, 39, 43, 0.1);

    img {
      margin: 0 15px 0 11px;
      width: 41px;
      height: 35px;
    }

    span {
      font-size: 28px;
      font-weight: 600;
      color: #0f0218;
      background: linear-gradient(0deg, #ffe5af 0%, #ffd373 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    button {
      margin-left: auto;
      height: 60px;
      background: linear-gradient(270deg, #ffd373 0%, #ffe5af 100%);
      border-radius: 6px;
      padding: 0 25px;
      font-size: 24px;
    }
  }
  .coBranding {
    margin: 30px 0;
    text-align: center;
    img {
      width: 690px;
    }
  }
}
</style>
