<template>
  <div class="userBody">
    <userPageHead />
    <!-- 第一行 -->
    <div class="statisticsRow">
      <h3 @click="$fun.routerToPage('/user/order')">
        <span>{{ $fanyi("订单") }}</span>
        <span class="checkAll"
          >{{ $fanyi("所有") }} <van-icon name="arrow"
        /></span>
      </h3>
      <div class="aListOfData fourOfOneRow">
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/order?type=temporary')"
        >
          <span v-if="findNum(ordersSum, '临时保存待提出')">{{
            findNum(ordersSum, "临时保存待提出")
          }}</span>
          <img :src="require('@/assets/homePage/bi6.png')" alt="" />
          <p class="smallFont">{{ $fanyi("临时保存（未提交）") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/order?type=offer')"
        >
          <span v-if="findNum(ordersSum, '报价中')">{{
            findNum(ordersSum, "报价中")
          }}</span>
          <img :src="require('@/assets/homePage/bi7.png')" alt="" />
          <p>{{ $fanyi("报价中") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/order?type=payment')"
        >
          <span v-if="findNum(ordersSum, '等待付款')">{{
            findNum(ordersSum, "等待付款")
          }}</span>
          <img :src="require('@/assets/homePage/bi8.png')" alt="" />
          <p>{{ $fanyi("等待付款") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/order?type=purchase')"
        >
          <span v-if="findNum(ordersSum, '正在购买')">{{
            findNum(ordersSum, "正在购买")
          }}</span>
          <img :src="require('@/assets/homePage/bi23.png')" alt="" />
          <p>{{ $fanyi("正在购买") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/order?type=completepurchase')"
        >
          <span v-if="findNum(ordersSum, '采购完了')">{{
            findNum(ordersSum, "采购完了")
          }}</span>
          <img :src="require('@/assets/homePage/bi24.png')" alt="" />
          <p>{{ $fanyi("购买完成") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/order?type=completedelivery')"
        >
          <span v-if="findNum(ordersSum, '出货完成')">{{
            findNum(ordersSum, "出货完成")
          }}</span>
          <img :src="require('@/assets/homePage/bi15.png')" alt="" />
          <p>{{ $fanyi("出货完成") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/order?type=cancel')"
        >
          <!-- <span v-if="findNum(ordersSum, '已取消')">{{
            findNum(ordersSum, "已取消")
          }}</span> -->
          <img :src="require('@/assets/homePage/bi11.png')" alt="" />
          <p>{{ $fanyi("已取消") }}</p>
        </div>
        <div class="aListOfDataOpt"></div>
      </div>
    </div>
    <!-- 第二行 -->
    <div class="statisticsRow">
      <h3>
        <span @click="$fun.routerToPage('/user/wuliuStatus')">{{
          $fanyi("中国国内状态")
        }}</span>
      </h3>
      <div class="aListOfData">
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/wuliuStatus?type=Waittobuy')"
        >
          <span v-if="findNum(chinaLogisticsListTableNum, '等待购买')">{{
            findNum(chinaLogisticsListTableNum, "等待购买")
          }}</span>
          <div class="imgBox">
            <img :src="require('@/assets/homePage/bi29.png')" alt="" />
          </div>

          <p>{{ $fanyi("等待购买") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/wuliuStatus?type=toBeShipped')"
        >
          <span v-if="findNum(chinaLogisticsListTableNum, '待发货')">{{
            findNum(chinaLogisticsListTableNum, "待发货")
          }}</span>
          <div class="imgBox">
            <img :src="require('@/assets/homePage/bi25.png')" alt="" />
          </div>

          <p>{{ $fanyi("待发货") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/wuliuStatus?type=Domesticdelivery')"
        >
          <span v-if="findNum(chinaLogisticsListTableNum, '国内派送中')">{{
            findNum(chinaLogisticsListTableNum, "国内派送中")
          }}</span>
          <div class="imgBox">
            <img :src="require('@/assets/homePage/bi28.png')" alt="" />
          </div>

          <p>{{ $fanyi("国内派送中") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/wuliuStatus?type=receipt')"
        >
          <span v-if="findNum(chinaLogisticsListTableNum, '已签收')">{{
            findNum(chinaLogisticsListTableNum, "已签收")
          }}</span>
          <div class="imgBox">
            <img :src="require('@/assets/homePage/bi26.png')" alt="" />
          </div>

          <p
            v-html="($fanyi('已签收') || '').replace('待ち', `<br />待ち`)"
          ></p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/wuliuStatus?type=Intheshelf')"
        >
          <span v-if="findNum(chinaLogisticsListTableNum, '上架中')">{{
            findNum(chinaLogisticsListTableNum, "上架中")
          }}</span>
          <div class="imgBox">
            <img :src="require('@/assets/homePage/bi27.png')" alt="" />
          </div>

          <p>{{ $fanyi("商品作业中") }}</p>
        </div>
        <!-- 2023/6/16 这个状态不需要显示了 -->
        <!-- <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/wuliuStatus?type=Oncompletion')"
        >
          <span v-if="findNum(chinaLogisticsListTableNum, '上架完成')">{{
            findNum(chinaLogisticsListTableNum, "上架完成")
          }}</span>
          <img :src="require('@/assets/homePage/bi5.png')" alt="" />
          <p>{{ $fanyi("上架完成") }}</p>
        </div> -->
      </div>
    </div>

    <!-- 第三行 仓库 -->
    <div class="warehouse">
      <div
        class="warehouseOpt"
        @click="$fun.routerToPage('/warehouse?type=gratis')"
      >
        <span>
          {{ $fanyi("普通仓库") }}
        </span>
        <div class="warehouseIcon">
          <span v-if="findNum(stockTableNum, '普通仓库')">{{
            findNum(stockTableNum, "普通仓库")
          }}</span>
          <img :src="require('@/assets/homePage/bi20.png')" alt="" />
        </div>
      </div>
      <div
        class="warehouseOpt"
        @click="$fun.routerToPage('/warehouse?type=pay')"
      >
        <span>
          {{ $fanyi("收费仓库") }}
        </span>
        <div class="warehouseIcon">
          <span v-if="findNum(stockTableNum, '收费仓库')">{{
            findNum(stockTableNum, "收费仓库")
          }}</span>
          <img :src="require('@/assets/homePage/bi21.png')" alt="" />
        </div>
      </div>
    </div>
    <!-- 第四行 -->
    <div class="statisticsRow">
      <h3 @click="$fun.routerToPage('/user/deliveryList')">
        <span>{{ $fanyi("配送单") }}</span>
        <span class="checkAll"
          >{{ $fanyi("所有") }} <van-icon name="arrow"
        /></span>
      </h3>
      <div class="aListOfData fourOfOneRow">
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/deliveryList?type=temporary')"
        >
          <span v-if="findNum(storageDeliverNum, '临时保存')">{{
            findNum(storageDeliverNum, "临时保存")
          }}</span>
          <img :src="require('@/assets/homePage/bi16.png')" alt="" />
          <p class="smallFont">{{ $fanyi("临时保存（未提交）") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/deliveryList?type=vanning')"
        >
          <span v-if="findNum(storageDeliverNum, '装箱中')">{{
            findNum(storageDeliverNum, "装箱中")
          }}</span>
          <img :src="require('@/assets/homePage/bi17.png')" alt="" />
          <p>{{ $fanyi("装箱中") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/deliveryList?type=payment')"
        >
          <span v-if="findNum(storageDeliverNum, '等待付款')">{{
            findNum(storageDeliverNum, "等待付款")
          }}</span>
          <img :src="require('@/assets/homePage/bi8.png')" alt="" />
          <p>{{ $fanyi("等待付款") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/deliveryList?type=sendout')"
        >
          <span v-if="findNum(storageDeliverNum, '发货中')">{{
            findNum(storageDeliverNum, "发货中")
          }}</span>
          <img :src="require('@/assets/homePage/bi18.png')" alt="" />
          <p>{{ $fanyi("发货中") }}</p>
        </div>

        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/deliveryList?type=shipped')"
        >
          <span v-if="findNum(storageDeliverNum, '已发货')">{{
            findNum(storageDeliverNum, "已发货")
          }}</span>
          <img :src="require('@/assets/homePage/bi19.png')" alt="" />
          <p>{{ $fanyi("已发货") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/deliveryList?type=delivered')"
        >
          <!-- 2023/8/8 这个数字不用显示 -->
          <!-- <span v-if="findNum(storageDeliverNum, '已签收')">{{
            findNum(storageDeliverNum, "已签收")
          }}</span> -->
          <img :src="require('@/assets/homePage/bi19.png')" alt="" />
          <p>{{ $fanyi("配送单已签收") }}</p>
        </div>
        <div
          class="aListOfDataOpt"
          @click="
            $fun.routerToPage('/user/deliveryList?type=receivedSuccessfully')
          "
        >
          <!-- <span v-if="findNum(storageDeliverNum, '已取消')">{{
            findNum(storageDeliverNum, "已取消")
          }}</span> -->
          <img :src="require('@/assets/homePage/bi11.png')" alt="" />
          <p>{{ $fanyi("已取消") }}</p>
        </div>
        <div class="aListOfDataOpt"></div>
      </div>
    </div>
    <!-- 第五行 -->
    <div class="statisticsRow">
      <h3>
        <span>{{ $fanyi("问题处理") }}</span>
      </h3>
      <div class="aListOfData">
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/issueOrder')"
        >
          <span v-if="findNum(numberOfProblematicProducts, '待回复')">{{
            findNum(numberOfProblematicProducts, "待回复")
          }}</span>
          <img :src="require('@/assets/homePage/bi12.png')" alt="" />
          <p>{{ $fanyi("问题产品") }}</p>
        </div>
        <!-- 说还没有上线,先不展示 -->
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/aftersale')"
          v-if="false"
        >
          <img :src="require('@/assets/homePage/bi13.png')" alt="" />
          <p>{{ $fanyi("售后") }}</p>
        </div>
        <div class="aListOfDataOpt"></div>
        <div class="aListOfDataOpt"></div>
        <div class="aListOfDataOpt"></div>
      </div>
    </div>
    <!-- 第六行 -->
    <div class="statisticsRow">
      <h3>
        <span>{{ $fanyi("收藏") }}</span>
      </h3>
      <div class="aListOfData">
        <div
          class="aListOfDataOpt"
          @click="$fun.routerToPage('/user/commodity')"
        >
          <span v-if="findNum(goodsTableNum, '上架中')">{{
            findNum(goodsTableNum, "上架中")
          }}</span>
          <img :src="require('@/assets/homePage/bi14.png')" alt="" />
          <p>{{ $fanyi("收藏商品") }}</p>
        </div>
        <div class="aListOfDataOpt"></div>
        <div class="aListOfDataOpt"></div>
        <div class="aListOfDataOpt"></div>
        <div class="aListOfDataOpt"></div>
      </div>
    </div>
    <div class="technicalSupport">
      {{ $fanyi("由1688提供部分技术服务⽀持") }}
    </div>
    <footerBarVue />
  </div>
</template>
<script setup>
import userPageHead from "./components/userPageHead.vue";
import footerBarVue from "../../components/footBar/index.vue";
import { ref, getCurrentInstance } from "vue";
const { proxy } = getCurrentInstance();
const chinaLogisticsListTableNum = ref([]); //国内物流数量
const stockTableNum = ref([]); //仓库数量
const ordersSum = ref([]); //订单数量
const storageDeliverNum = ref([]); //配送单数量
const goodsTableNum = ref([]); //商品收藏数量
const numberOfProblematicProducts = ref([]); //问题产品数量
// 获取统计数据
const getNum = () => {
  proxy.$api.chinaLogisticsListTableNum().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
    chinaLogisticsListTableNum.value = res.data;
  });
  proxy.$api.ordersSum().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
    ordersSum.value = res.data;
  });
  proxy.$api.stockTableNum().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
    stockTableNum.value = res.data;
  });
  proxy.$api.storageDeliverNum().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
    storageDeliverNum.value = res.data;
  });
  proxy.$api.goodsTableNum().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
    goodsTableNum.value = res.data;
  });
  proxy.$api.problemGoodsOrderNum().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
    numberOfProblematicProducts.value = res.data;
  });
};
// 从获取的数据列表中查找出显示的数字
const findNum = (list, name) => {
  let num = 0;
  let jieguo = list.find((item) => {
    return item.name == name;
  });
  if (!!jieguo) {
    num = jieguo.count;
  }
  return num;
};
getNum();
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.userBody {
  background: linear-gradient(
    180deg,
    #ffedea 0%,
    #ffffff 12%,
    #f6f6f6 27.99999999999999%
  );
  border-radius: 6px;
}

.statisticsRow {
  padding: 40px 30px;
  margin: 30px;
  background-color: white;

  h3 {
    display: flex;
    justify-content: space-between;
    line-height: 42px;

    span {
      font-size: 28px;
      font-weight: 600;
    }

    .checkAll {
      font-size: 24px;
      color: #999999;
      font-weight: 400;
    }
  }

  .aListOfData {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .aListOfDataOpt {
      min-width: 96px;
      height: 116px;
      position: relative;
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .imgBox {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 16px auto 20px;
      }
      img {
        width: 48px;
        display: block;
        max-height: 48px;
        margin: 16px auto 20px;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        $size: 36px;
        min-width: $size;
        padding: 0 8px;
        height: $size;
        background-color: #ff4437;
        border-radius: $size/2;
        position: absolute;
        top: 0;
        right: 11px;
        text-align: center;
        line-height: $size;
        font-size: 24px;
        color: #ffffff;
        border: solid 3px white;
      }
      p {
        display: block;
        margin-top: auto;
        line-height: 1.1;
        height: 36px;
        font-size: 24px;
        text-align: center;
      }
    }
  }

  .fourOfOneRow {
    .aListOfDataOpt {
      width: 96px;
      min-width: 96px;
      margin-right: 50px;

      p {
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .smallFont {
        font-size: 20px;
        white-space: wrap;
        line-height: 1.2;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
.technicalSupport {
  margin: 30px 0;
  font-size: 20px;
  color: #999999;
  line-height: 1;
  text-align: center;
}
.warehouse {
  display: flex;
  justify-content: space-between;
  margin: 30px;

  .warehouseOpt {
    width: 330px;
    padding: 30px 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 28px;
      font-weight: 600;
      margin-right: 30px;
    }

    .warehouseIcon {
      position: relative;

      img {
        $size: 64px;
        width: $size;
        height: $size;
      }

      span {
        $size: 36px;
        min-width: $size;
        padding: 0 8px;
        height: $size;
        background: #ff4437;
        border: 3px solid #ffffff;
        border-radius: 15px;
        font-size: 24px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 40px;
        top: -10px;
      }
    }
  }
}
</style>
